<template>
  <div>
    <a-button-group>
      <a-button v-if="item.status === 'editing'" size="small" @click="updateModalVisible = true">编辑</a-button>
      <a-popconfirm v-if="item.status === 'editing'" title="确定启用吗" @confirm="comfirm">
        <a-button type="primary" size="small">启用</a-button>
      </a-popconfirm>
      <a-popconfirm v-if="item.status === 'editing' || item.status === 'closed'" title="确定删除吗" @confirm="destroy">
        <a-button type="danger" size="small">删除</a-button>
      </a-popconfirm>
      <a-popconfirm v-if="item.status === 'completed'" title="确定停用吗" @confirm="close">
        <a-button type="danger" size="small">停用</a-button>
      </a-popconfirm>
    </a-button-group>

    <update-form-modal v-model="updateModalVisible" :item="item" @update="update" @cancel="updateModalVisible = false" />
  </div>
</template>

<script>
import { maintenancePlanDestroy, maintenancePlanComfirm, maintenancePlanClose } from "@/api/equipment";

export default {
  components: {
    UpdateFormModal: () => import("./UpdateFormModal"),
  },
  props: ["item"],
  data() {
    return {
      updateModalVisible: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    destroy() {
      maintenancePlanDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
    comfirm() {
      maintenancePlanComfirm({ id: this.item.id }).then((data) => {
        this.$message.success("启用成功");
        this.$emit("update", data);
      });
    },
    close() {
      maintenancePlanClose({ id: this.item.id }).then((data) => {
        this.$message.success("停用成功");
        this.$emit("update", data);
      });
    },
  },
};
</script>

<style scoped></style>
